<template>
  <Carousel :slide="currentSlide" :autoplay="6000" :wrap-around="true" v-model="currentSlide">
    <template v-for="(slide, index) in slides" :key="index">
      <Slide>
        <div class="carousel__item">
          <div class="row col-12 d-flex justify-content-center align-items-center">
            <img :src="slide.imageSrc" class="img-fluid imgCarousel" alt="">
          </div>
          <div class="row mt-3">
            <div class="col-12 d-flex justify-content-center align-items-center text-left">
              <img :src="slide.iconSrc" class="img-fluid icon" alt="">
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center text-left">
              <p class="colorRedBig"><strong>{{ slide.title }}</strong></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center">
              <p class="colorBlack description text-left">{{ slide.description }}</p>
            </div>
          </div>
        </div>
      </Slide>
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default defineComponent({
  name: 'Autoplay',
  data() {
    return {
      currentSlide: 0,
      slides: [
        {
          imageSrc: require('@/assets/img/companies/McAfee/McafeeHP/CX-banner-1-1.png'),
          iconSrc: require('@/assets/img/companies/McAfee/McafeeHP/Password.png'),
          title: 'GERENCIADOR DE SENHAS',
          description: 'Armazene e gerencie com segurança todas as suas senhas online em um único local. O True Key protege suas senhas criptografando-as com AES-256, um dos algoritmos de criptografia mais poderosos. Somente você poderá descriptografá-las e acessar suas informações com os fatores que você selecionar.'
        },
        {
          imageSrc: require('@/assets/img/companies/McAfee/McafeeHP/CX-banner-1-2.png'),
          iconSrc: require('@/assets/img/companies/McAfee/McafeeHP/wifi.png'),
          title: 'WI-FI SCAN',
          description: 'Receba alertas oportunos ao tentar-se conectar a um Wi-Fi público para que você possa escolher uma rede Wi-Fi diferente ou ativar a VPN para uma conexão online. Evite conectar-se a um Wi-Fi inseguro e ter seus dados pessoais expostos.'
        },
        {
          imageSrc: require('@/assets/img/companies/McAfee/McafeeHP/CX-banner-1-3.png'),
          iconSrc: require('@/assets/img/companies/McAfee/McafeeHP/dark.png'),
          title: 'DARK WEB MONITOR',
          description: 'Monitoramento a dark web por até 10 endereços de e-mail e, se encontrados, notificaremos você em média 10 meses antes de nossos concorrentes, para que você possa agir antes que suas informações acabem nas mãos erradas. Examinaremos a dark web para ver se seu endereço de e-mail foi exposto em uma violação de dados, permitindo que você aja antes que suas informações acabem nas mãos erradas.'
        }
      ]
    };
  },
  components: {
    Carousel,
    Slide
  },
  props: {
    slide: Number
  }
})
</script>
<style>
.cardWhite {
  color: crimson;
  background-color: #FFFFFF;
}
.colorBlack {
  font-family: Roboto;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.colorRedBig {
  color: crimson;
  font-size: 20px;
}
.colorWhite {
  color: #FFFFFF;
}
.carousel__pagination-button {
  color: #444444;
}
.icon{
  width: 50px;
  height: 50px;
  padding-right: 10px;
}
.imgCarousel{
  width: 600px;
  height: 337px; 
  object-fit: contain;
}
.teste{
  display: flex;
  justify-content: center;
}
.description {
  display: block;
  width: 50%; 
  margin: 0 auto; 
  text-align: center;
  white-space: pre-line; 
}
@media (min-width: 320px) and (max-width: 768px) {
  .description {
    width: auto !important;
  }
   .text-left {
    justify-content: left !important;
     text-align: left !important;
  }
}
</style>
