
<template>
  <div>
    <HomeContainer>
      <template v-slot:header>
        <Header :hideIcon="true" :logoRedirectUrl="'/infopromohp'"/>
      </template>
      <template v-slot:main>
        <HomePageStatic :title="pageTitle" />
      </template>
      <template v-slot:footer>
        <Footer />
      </template>      
    </HomeContainer>
  </div>
</template>
<script>
import Footer from "@/components/Footer/Footer.vue";
import HomePageStatic from "@/components/Homepages/mcafeeStatic/HomePageStatic.vue";
import Header from "@/components/Header/Header.vue";
import HomeContainer from "@/components/Container/HomeContainer.vue";

export default {
  name: "Home",
  components: {
    Header,
    HomePageStatic,
    Footer,
    HomeContainer
  },
   data() {
    return {
      pageTitle: 'Bem vindo a página da Mcafee'
    };
  }
};
</script>